// Vue JS本体モジュールの読み込み
import Vue from 'vue';
import Vuex from 'vuex';
import vuetify from './plugin/vuetify';
import router from "./routes/indexRouter.js";
import vueConfig from './vue.config.js';
// Vueで使用するHTTPクライアントモジュールの読み込み
import axios from 'axios';
// Setup VeeValidate
import {setupVeeValidate} from './plugin/veeValidate.js';
setupVeeValidate(Vue);

import {moduleBookshelfScan} from './store/modules/bookshelfScan.js';
import {moduleFavorite} from "./store/modules/favorite.js";
import {moduleLibrary} from "./store/modules/library.js";
import {moduleSearch} from "./store/modules/search.js";
import {moduleAudio} from "./store/modules/audio.js";
import {moduleItem} from "./store/modules/item.js";
import {moduleOpenAi} from "./store/modules/openAi.js";

/**
 * コンポーネントの登録
 */
import {VApp, VMain, Resize} from 'vuetify/lib';
import VbHeader from "./components/organisms/vb-header.vue";
import VbFooter from "./components/organisms/vb-footer.vue";

window.VueConfig = vueConfig;
window.axios = axios;

/**
 * カスタムディレクティブ
 */
// v-showではdisplay:noneしかできないため、visibility: hiddenを使えるようにする
// v-visible=(条件)で使用する
Vue.directive('visible', function (el, binding) {
  el.style.visibility = !!binding.value ? 'visible' : 'hidden';
});
Vue.directive('resize', Resize)
/**
 * フィルター定義
 */
// 金額をカンマ区切りにする
Vue.filter('addCommaSeparator', function (value) {
  return value == null || isNaN(value) ? '' : value.toLocaleString();
});

// hiddenから取得したcustomerId
const bdCodeElement = document.getElementById('bd_code1');
Vue.prototype.$customerId = bdCodeElement ? bdCodeElement.value : null;

new Vue({
  el: '#vb-app',
  router,
  vuetify,
  store: new Vuex.Store({
    modules: {
      bookshelfScan: moduleBookshelfScan,
      favorite: moduleFavorite,
      library: moduleLibrary,
      search: moduleSearch,
      audio: moduleAudio,
      item: moduleItem,
      openAi: moduleOpenAi,
    }
  }),
  components: {
    VApp,
    VMain,
    VbHeader,
    VbFooter,
  },
});
